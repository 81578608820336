(function loadSVGs() {
    if (!window.spriteInjectInitialized) {
        const svgWrapper = document.getElementById('svg-all-wrapper');
        if (!svgWrapper) {
            const svgPath = document.getElementById('svg-path').href;
            var ajax = new XMLHttpRequest();
            ajax.open('GET', svgPath, true);
            ajax.send();
            ajax.onload = function () {
                var div = document.createElement('div');
                div.style.display = 'none';
                div.id = 'svg-all-wrapper';
                div.innerHTML = ajax.responseText;
                document.body.insertBefore(div, document.body.childNodes[0]);
            };
        }
        window.spriteInjectInitialized = true;
    }
})();